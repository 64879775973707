import { Color } from './../models/color.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({ providedIn: 'root' })

export class ColorService extends BaseService<Color>{
   
    constructor(
        protected injector: Injector,
    ){
        super(injector);
        this.copyJsonDataToObjectFunction=Color.fromJsonToObject
        this.urls={getAllUrl:'colors',saveUrl:'regCor',updateUrl:'up-cor',deleteUrl:'dst-cor'}
    }

}