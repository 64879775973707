import { Club } from './club.model';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppApiInterface } from 'src/app/app.api';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({providedIn: 'root'})

export class ClubsService extends BaseService<Club>{
    
    
   

    constructor(
        protected injector: Injector,
    ){
        super(injector);
        this.copyJsonDataToObjectFunction=Club.fromJsonToObject
        this.urls={
            getAllUrl:'clubs',
            saveUrl:'regClube',
            updateUrl:'up-clube',
            deleteUrl:'dst-clube',
            updateImageWithNoDataUrl:'up-clube-imagem'
        }
    }

}