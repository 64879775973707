import { City } from './../models/city.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({providedIn: 'root'})
export class CityService extends BaseService<City> {
    
    constructor(protected injector:Injector) {
        super(injector)
        this.copyJsonDataToObjectFunction=City.fromJsonToObject
        this.urls={getAllUrl:'cities'}
     }
    
}