import { Product } from './product.model';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppApiInterface } from 'src/app/app.api';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({ providedIn: 'root' })
export class ProductsService  extends BaseService<Product>{
   
    constructor(
        protected injector:Injector,
    ){
        super(injector);

        this.copyJsonDataToObjectFunction=Product.fromJsonToObject
        this.urls={
            getAllUrl:'products',
            saveUrl:'regProduct',
            updateUrl:'up-product',
            deleteUrl:'dst-product',
            saveImageWithNoDataUrl:'save-produto-imagem',
            updateImageWithNoDataUrl:'up-produto-imagem'
        }
    }

    getAllProducts(): Observable<any[]> {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/products`)
            .pipe(map(data => data))
    }

    saveProduct(product: FormData) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regProduct`, product)
            .pipe(map(data => data))
    }

    saveStock(stock: FormData) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regStock`, stock)
            .pipe(map(data => data))
    }

    saveCategory(category: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regCategory`, category)
        .pipe(map(data => data))
    }

    saveSubcategory(subcategory: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regSubcategory`, subcategory)
        .pipe(map(data => data))
    }
    saveColor(color: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regColor`, color)
        .pipe(map(data => data))
    }
    
    saveTamanho(size: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/regTamanho`, size)
        .pipe(map(data => data))
    }

    updateSubcategory(subcategory: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-subcategory/${subcategory.id}`, subcategory)
        .pipe(map(data => data))
    }

    updateCategory(category: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-category/${category.id}`, category)
        .pipe(map(data => data))
    }

    updateColor(color: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-color/${color.id}`, color)
        .pipe(map(data => data))
    }

    updateSize(size: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-tamanho/${size.id}`, size)
        .pipe(map(data => data))
    }

    deleteCategory(idcategory: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-category/${idcategory}`)
        .pipe(map(data => data))
    }

    deleteColor(idColor: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-color/${idColor}`)
        .pipe(map(data => data))
    }

    deleteSize(idSize: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-tamanho/${idSize}`)
        .pipe(map(data => data))
    }
    
    deleteSubcategory(idsubcategory: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-subcategory/${idsubcategory}`)
        .pipe(map(data => data))
    }

    updateProduct(product: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-product/${product.get('id')}`, product)
            .pipe(map(data => data))
    }

    updateStock(stock: any) {
        return this.http.post<any>(`${AppApiInterface.IMALI_API}/up-stock/${stock.get('id')}`, stock)
            .pipe(map(data => data))
    }

    deleteProduct(product: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-product/${product.id}`)
            .pipe(map(data =>data))
    }

    deleteStock(product: any) {
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/dst-product-item/${product.id}`)
            .pipe(map(data =>data))
    }
}