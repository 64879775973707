import { Quote } from './../quotes/quote.model';
import { BaseModel } from 'src/app/shared/models/base.model';

export class Club extends BaseModel{
    public quotas:Array<Quote>;
    public color:string;
    public phone:string;
    public picture:string;
    public name:string;
    public nuit:string;
    public city:any;
    public address:string;
    public responsible_name:string;
    public responsible_phone:string;
    public responsible_email:string;
    public created_at:string;

    static fromJsonToObject(jsonData:any):Club{
        return Object.assign(new Club(), jsonData)
    }
}