import { Component } from '@angular/core';

@Component({
    selector: 'ng-box-table-spinner',
    templateUrl: 'box-table-spinner.component.html'
})

export class BoxTableSpinnerComponent {
    public faSpinnerClass:string;

    constructor() { 
        this.faSpinnerClass='fa fa-circle-o-notch fa-spin'
    }

}