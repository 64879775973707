import { Partner } from './partner.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({ providedIn: 'root' })

export class PartnersService extends BaseService<Partner> {
   

    constructor(
        injector: Injector,
    ) { 
        super(injector)
        this.copyJsonDataToObjectFunction=Partner.fromJsonToObject
        this.urls={
            getAllUrl:'partiners',
            saveUrl:'regParceiro',
            updateUrl:'up-parceiro',
            deleteUrl:'dst-parceiro',
            updateImageWithNoDataUrl:'up-parceiro-imagem'
        }
    }

}