import { BaseModel } from 'src/app/shared/models/base.model';
import { Service } from '../servicos/service.model';

export class Partner extends BaseModel{
    public services: Service[];
    public responsible_name:any;
    public responsible_phone:any;
    public responsible_email:any;
    public picture:any;
    public name:any;
    public partner_description:any;
    public nuit:any;
    public phone:any;
    public city:any;
    public address:any;
    public created_at:any;
    
    static fromJsonToObject(jsonData:any):Partner{
        return Object.assign(new Partner(), jsonData)
    }
}