import { Category } from './../models/category.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({ providedIn: 'root' })

export class CategoryService extends BaseService<Category>{
   
    constructor(
        protected injector: Injector,
    ){
        super(injector);
        this.copyJsonDataToObjectFunction=Category.fromJsonToObject
        this.urls={getAllUrl:'categories',saveUrl:'regCategoria',updateUrl:'up-categoria',deleteUrl:'dst-categoria'}
    }

}