import { Size } from './../models/size.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({ providedIn: 'root' })

export class SizeService extends BaseService<Size>{
   
    constructor(
        protected injector: Injector,
    ){
        super(injector);
        this.copyJsonDataToObjectFunction=Size.fromJsonToObject
        this.urls={getAllUrl:'sizes',saveUrl:'regTamanho',updateUrl:'up-tamanho',deleteUrl:'dst-tamanho'}
    }

}