import { AppApiInterface } from 'src/app/app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ApiConstants {

    private _appConstants:BehaviorSubject<Constant>=new BehaviorSubject<Constant>(null);

    private constants:Constant;

    constructor(private http: HttpClient) { 
        this.constants = {};
    }

    getConstants():Observable<Constant> {

        return this._appConstants.pipe(map(constants=>{
            if(!constants) return JSON.parse(localStorage.get('constants'));
        
            return constants;
        }))

    }

    setConstants(constants:Constant) {
        this._appConstants.next(constants);

        localStorage.setItem('constants',JSON.stringify(this.constants));
    }

    getAllBonusFormats():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/bonus-formats`)
        .pipe(map(response=>{

            this.constants.bonusFormats=response.data;

            this.setConstants(this.constants);

            return this.constants.bonusFormats;
        }))
    }

    getAllPeriods():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/periods`)
        .pipe(map(response=>{
            this.constants.periods=response.data;

            this.setConstants(this.constants);

            return this.constants.periods;
        }))
    }

    getAllRoles():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/roles`)
        .pipe(map(response=>{
            this.constants.roles=response.data;

            this.setConstants(this.constants);

            return this.constants.roles;
        }))
    }

    getAllProductsStatus():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/product-status`)
        .pipe(map(response=>{
            this.constants.productStatus=response.data;

            this.setConstants(this.constants);

            return this.constants.productStatus;
        }))
    }

    getAllPostsStatus():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/post-status`)
        .pipe(map(response=>{
            this.constants.postStatus=response.data;

            this.setConstants(this.constants);

            return this.constants.postStatus;
        }))
    }

    getAllDocuments():Observable<any>{
        return this.http.get<any>(`${AppApiInterface.IMALI_API}/documents`)
        .pipe(map(response=>{
            this.constants.documents=response.data;

            this.setConstants(this.constants);

            return this.constants.documents;
        }))
    }
    
}


interface Constant{
    percentages?:Array<any>;
    documents?:Array<any>;
    postStatus?:Array<any>;
    productStatus?:Array<any>;
    roles?:Array<any>;
    periods?:Array<any>;
    bonusFormats?:Array<any>;
}

