import { BaseModel } from 'src/app/shared/models/base.model';

export class Product extends BaseModel{

    image:any;
    product_code:any;
    title:any;
    description:any;
    category:any;
    subcategory:any;
    clubColor:any;
    clubName:any;
    subcategorias:any;

    static fromJsonToObject(jsonData:any):Product{
        return Object.assign(new Product(),jsonData);
    }
}